import type { ReactNode } from 'react';
import { NowFromServerContextProvider } from '@flexteam/hooks-date';
import type { Locale } from '@flexteam/i18n-base';
import {
  ToastProvider as LinearToastProvider,
  LocaleProvider as LinearLocaleProvider,
  TooltipProvider as LinearTooltipProvider,
} from '@flexteam/fx';

import UserAgentFromServerContextProvider from './UserAgentFromServerContextProvider';

interface Props {
  locale: Locale;
  now: number;
  userAgent: string;
  children: ReactNode;
}

export function CommonsProvider({ locale, now, userAgent, children }: Props) {
  return (
    <NowFromServerContextProvider nowFromServer={now}>
      <UserAgentFromServerContextProvider userAgent={userAgent}>
        <LinearLocaleProvider locale={locale}>
          <LinearToastProvider>
            <LinearTooltipProvider>{children}</LinearTooltipProvider>
          </LinearToastProvider>
        </LinearLocaleProvider>
      </UserAgentFromServerContextProvider>
    </NowFromServerContextProvider>
  );
}
