import type { ReactNode } from 'react';
import { userAgentFromServerContext } from '@flexteam/react-context';

interface Props {
  userAgent: string;
  children: ReactNode;
}

export default function UserAgentFromServerContextProvider({
  userAgent,
  children,
}: Props) {
  return (
    <userAgentFromServerContext.Provider value={userAgent}>
      {children}
    </userAgentFromServerContext.Provider>
  );
}
