// TODO: 공통 코드로 추상화 하기 (shared-next, public-recruiting, public-careers-team)
import type { ParsedUrlQuery } from 'querystring';

export const UTM_IDENTITIES = [
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
];

export function generateFlexUTMs(query: ParsedUrlQuery) {
  return Object.entries(query).reduce<ParsedUrlQuery>((accm, [key, value]) => {
    if (UTM_IDENTITIES.includes(key)) {
      accm[`flex_${key}`] = value;
    }

    return accm;
  }, {});
}
